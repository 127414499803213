<template>
  <div id="order-view-additional-cancelled" class="mt-2">
    <b-overlay :show="quoteLoading">
      <b-card no-body>
        <b-card-body v-if="quoteSelected.quote.cancelled">
          <b-link v-scroll-to="'#order-view-cancellation-policies-bloc'" class="text-body">
            <h6 class="font-weight-bold mb-2">{{ ` ${$tc('cancellation_policy.title', 1)} : ` }}</h6>
          </b-link>
          <OrderViewAdditionalCancelledCondition :count-services-cancelled="countServicesCancelled" :count-services-rescheduled="countServicesRescheduled" />
          <!-- cancellation coditions count -->
          <b-link v-if="cancellationPolicyType" v-scroll-to="'#order-view-cancellation-policies-bloc'" class="text-body">
            <h6 v-if="cancellationPolicyType === 'cancelled'" class="font-weight-bold my-2">
              {{ `${$t('order.cancelled.count_services_cancelled')} : ` }}
              <b-badge pill variant="primary">
                {{ countServicesCancelled }}
              </b-badge>
            </h6>
            <h6 v-if="cancellationPolicyType === 'rescheduled'" class="font-weight-bold my-2">
              {{ `${$t('order.cancelled.count_services_rescheduled')} : ` }}
              <b-badge pill variant="primary">
                {{ countServicesRescheduled }}
              </b-badge>
            </h6>
          </b-link>

          <!-- note -->
          <div class="d-flex justify-content-between align-items-center mb-1">
            <span>{{ $t('common.note') }} {{ $t('order.cancelled.sequestered_money') }}</span>
          </div>

          <!-- note_condition -->
          <div class="d-flex justify-content-between align-items-center mb-2">
            <span>{{ $t('order.cancelled.non_escrow_money') }}</span>
          </div>

          <OrderViewAdditionalCancelledNotApply />
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { mapFields } from 'vuex-map-fields'

import OrderViewAdditionalCancelledCondition from '@/views/order/OrderViewAdditionalCancelledCondition.vue'
import OrderViewAdditionalCancelledNotApply from '@/views/order/OrderViewAdditionalCancelledNotApply.vue'

export default {
  name: 'OrderViewAdditionalCancelled',

  components: {
    OrderViewAdditionalCancelledCondition,
    OrderViewAdditionalCancelledNotApply,
  },

  data() {
    return {
      countServicesCancelled: 0,
      countServicesRescheduled: 0,
    }
  },

  computed: {
    ...mapState('quote', ['isQuoteOwner']),
    ...mapFields('quote', ['quoteLoading', 'quoteSelected.quote.cancellationPolicyType', 'quoteSelected']),
  },

  watch: {
    cancellationPolicyType: {
      handler(value) {
        value && this.countServicesConditions()
      },
    },
  },

  mounted() {
    this.countServicesConditions()
  },

  methods: {
    countServicesConditions() {
      this.quoteSelected.quote.lines.forEach(line => {
        if (line.conditions.length) {
          line.conditions.forEach(condition => {
            if (condition.ifClause === 'for_cancellation') {
              this.countServicesCancelled += 1
            }
            if (condition.ifClause === 'for_rescheduled_trips') {
              this.countServicesRescheduled += 1
            }
          })
        }
      })
    },
  },
}
</script>
