<template>
  <div id="order-view-additional-cancelled-condition">
    <enum-select
      id="cancellation-policy-type"
      v-model="temporaryCancellationPolicyType"
      :clearable="false"
      :label="$t('cancellation_policy.condition.title')"
      :searchable="false"
      :selectable="value => enableConditionOptionsChoice(value)"
      :disabled="!!cancellationPolicyType"
      class="select-size-md"
      enum-class="quoteCancellationPolicyType"
      @option:selected="confirm = true"
    />

    <!-- TODO implement @hidden="temporaryCancellationPolicyType = ''" after api fix -->
    <b-sidebar id="order-view-additional-cancelled-condition-confirm-sidebar" :visible="confirm" no-close-on-backdrop @change="confirm = $event">
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">{{ $t('action.not_apply') }} {{ $t('action.edition') }}</h5>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide(), (temporaryCancellationPolicyType = '')" />
        </div>
        <div class="p-2">
          <h4 class="mb-2">{{ $t('order.cancellation.chose_question') }}</h4>
          <p class="mb-4">{{ $t('order.cancellation.chose_text') }}</p>
          <b-button
            id="order-view-additional-cancelled-condition-confirm-action-yes"
            type="button"
            variant="outline-secondary"
            block
            class="w-100"
            @click.prevent="preRenderQuoteWithCancellationsAndEditionMode(), hide()"
          >
            {{ $t('common.yes') }}
          </b-button>
          <b-button
            id="order-view-additional-cancelled-condition-confirm-action-no"
            type="button"
            variant="outline-secondary"
            block
            class="w-100"
            @click.prevent="hide(), (temporaryCancellationPolicyType = '')"
          >
            {{ $t('common.no') }}
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import { fetchQuoteCancellationPolicyRequest } from '@/request/globalApi/requests/quoteRequests'

export default {
  name: 'OrderViewAdditionalCancelledCondition',

  props: {
    countServicesCancelled: {
      type: Number,
      default: 0,
    },
    countServicesRescheduled: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      confirm: false,
      temporaryCancellationPolicyType: '',
    }
  },

  computed: {
    ...mapFields('quote', ['quoteLoading', 'quoteEditable', 'quoteSelected.quote.cancellationPolicyType', 'quoteSelected.quote']),
  },

  watch: {
    cancellationPolicyType: {
      handler(cancellationPolicyType) {
        if (cancellationPolicyType) {
          this.temporaryCancellationPolicyType = cancellationPolicyType.slice()
        }
      },
    },
  },

  mounted() {
    this.temporaryCancellationPolicyType = this.cancellationPolicyType.slice()
    if (this.temporaryCancellationPolicyType !== '') {
      this.quoteEditable = true
    }
  },

  methods: {
    enableConditionOptionsChoice(opt) {
      if (opt.value === 'cancelled') return true
      if (opt.value === 'rescheduled') return true
      return false
    },

    preRenderQuoteWithCancellationsAndEditionMode() {
      this.quoteLoading = true
      fetchQuoteCancellationPolicyRequest(this.quote.id, this.temporaryCancellationPolicyType)
        .then(response => {
          this.quote = response.data.quote
        })
        .finally(() => {
          this.quoteLoading = false
          this.quoteEditable = true
        })
    },
  },
}
</script>
